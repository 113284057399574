
@import "custom/plugins/icons";


@keyframes loading-download-icon {
    to {
        transform: rotate(360deg);
    }
}

.animation-loader {
    animation: loading-download-icon ease-out 4s infinite;
}